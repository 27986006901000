import React from 'react';

import { Home } from '.';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';

const routes = [
  {
    path: '/',
    renderer: (params = {}): JSX.Element => <Home {...params} />,
  },
];

const Routes = (): JSX.Element => {
  return (
    <ReactRoutes>
      {routes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))}
      <Route path="*" element={<Navigate replace to="/not-found-cover" />} />
    </ReactRoutes>
  );
};


export default Routes;
