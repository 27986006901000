import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './components/Routes';
import Page from './components/Page';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faBuildingLock, faCheckSquare, faCircleCheck, faCircleXmark, faCoffee, faDesktop, faDisplay, faHouseSignal, faIndianRupeeSign, faMapLocationDot, faMobileScreenButton, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faDesktop,
  faDisplay,
  faHouseSignal,
  faIndianRupeeSign,
  faScrewdriverWrench,
  faBuildingLock,
  faMapLocationDot,
  faCircleCheck,
  faCircleXmark,
  faMobileScreenButton,
  faDesktop
);

// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

const App = (): JSX.Element => {
  return (
    <Page>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Page>
  );
};

export default App;
