import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ListItemText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const mock = [
  {
    title: 'Individual',
    price: 'Free',
    features: [
      {
        title: 'Seamless Communication',
        isMobile: true,
        isWeb: false,
      },
      {
        title: 'Stay connected with neighborhood',
        isMobile: true,
        isWeb: false,
      },
      {
        title: 'Rent and fee collections',
        isMobile: true,
        isWeb: false,
      },
      {
        title: 'Maintenance Requests',
        isMobile: true,
        isWeb: false,
      },
      {
        title: 'Visitor Management',
        isMobile: true,
        isWeb: false,
      },
      {
        title: 'Package Management',
        isMobile: true,
        isWeb: false,
      },
      {
        title: 'Parking Management',
        isMobile: true,
        isWeb: false,
      },
    ],
    isHighlighted: false,
    btnText: 'Get basic',
  },
  {
    title: 'Community',
    tag: 'Coming soon',
    price: '199',
    features: [
      {
        title: 'Seamless Communication',
        isMobile: true,
        isWeb: true,
      },
      {
        title: 'Stay connected with neighborhood',
        isMobile: true,
        isWeb: true,
      },
      {
        title: 'Rent and fee collections',
        isMobile: true,
        isWeb: true,
      },
      {
        title: 'Maintenance Requests',
        isMobile: true,
        isWeb: true,
      },
      {
        title: 'Visitor Management',
        isMobile: true,
        isWeb: true,
      },
      {
        title: 'Package Management',
        isMobile: true,
        isWeb: true,
      },
      {
        title: 'Parking Management',
        isMobile: true,
        isWeb: true,
      },
    ],
    isHighlighted: true,
    btnText: 'Get pro',
  },
];

const Pricing = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box maxWidth={900} margin={'0 auto'}>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography
            sx={{
              fontWeight: 'bold',
            }}
            color={'primary'}
            align={'center'}
            variant={'h5'}
          >
            Pricing
          </Typography>
        </Box>
        <Typography
          sx={{ fontWeight: 700 }}
          variant={'h4'}
          align={'center'}
          gutterBottom
        >
          Flexible and transparent pricing
        </Typography>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'text.secondary'}
          align={'center'}
        >
          Whatever your status, our offers evolve according to your needs.
        </Typography>
      </Box>
      <Grid container spacing={isMd ? 0 : 2}>
        {mock.map((item, i) => (
          <Grid
            item
            container
            alignItems={'center'}
            xs={12}
            md={6}
            key={i}
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Box>
              {item.tag ? <Box zIndex={2} position={'absolute'} p={0.3}
                sx={{ backgroundColor: theme.palette.secondary.main, borderRadius: 1 }}
                right={0}

              >
                <Typography
                  variant={'subtitle1'}
                  gutterBottom
                  align={'center'}
                  color={item.isHighlighted ? 'black' : 'text.secondary'}
                >
                  {item.tag ? item.tag : ''}
                </Typography>
              </Box> : null}
              <Box
                component={Card}
                display={'flex'}
                flexDirection={'column'}
                border={0}
                paddingY={item.isHighlighted && isMd ? 8 : 2}
              // sx={{ backgroundColor: 'red' }}
              >
                <CardContent>
                  <Box
                    marginBottom={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography variant={'h5'} gutterBottom>
                      <Box component={'span'} fontWeight={700}>
                        {item.title}
                      </Box>
                    </Typography>
                    <Box display={'flex'} alignItems={'flex-start'}>
                      {item.price !== 'Free' ? <Typography variant={'h4'} color={'primary'}>
                        <Box
                          component={'span'}
                          fontWeight={600}
                          marginRight={1 / 2}
                        >
                          ₹
                        </Box>
                      </Typography> : null}
                      <Typography variant={'h2'} color={'primary'} gutterBottom>
                        <Box component={'span'} fontWeight={600}>
                          {item.price}
                        </Box>
                      </Typography>
                    </Box>
                    {item.price !== 'Free' ? <Typography variant={'subtitle1'} color={'text.secondary'}>
                      Per unit, per month
                    </Typography> : null}
                  </Box>
                  <Grid container spacing={1} px={1}>
                    <Grid container spacing={1} marginBottom={1}>
                      <Grid item xs={9}>
                        {' '}
                      </Grid>
                      <Grid item xs={1.5}>
                        <FontAwesomeIcon size={'lg'} icon={['fas', 'mobile-screen-button']} color={theme.palette.text.primary} />
                      </Grid>
                      <Grid item xs={1.5}>
                        <FontAwesomeIcon size={'lg'} icon={['fas', 'desktop']} color={theme.palette.text.primary} />
                      </Grid>
                    </Grid>
                    {item.features.map((feature, j) => (
                      <Grid container spacing={1}>
                        <Grid item xs={9} key={j}>
                          <ListItemText primary={feature.title} />
                        </Grid>
                        <Grid item xs={1.5} key={j}>
                          {feature.isMobile ? <FontAwesomeIcon icon={['fas', 'circle-check']} color={theme.palette.secondary.main} /> : <FontAwesomeIcon icon={['fas', 'circle-xmark']} color={theme.palette.text.secondary} />}
                        </Grid>
                        <Grid item xs={1.5} key={j}>
                          {feature.isWeb ? <FontAwesomeIcon icon={['fas', 'circle-check']} color={theme.palette.secondary.main} /> : <FontAwesomeIcon icon={['fas', 'circle-xmark']} color={theme.palette.text.secondary} />}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  {/* <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button
                      size={'large'}
                      variant={item.isHighlighted ? 'contained' : 'outlined'}
                    >
                      {item.btnText}
                    </Button>
                  </CardActions> */}
                </CardContent>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box >
  );
};

export default Pricing;
