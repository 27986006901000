import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PhoneSkeletonIllustration from 'svg/illustrations/PhoneSkeleton';
import { Button, TextField } from '@mui/material';

const VideoSection = (): JSX.Element => {
  const theme = useTheme();
  const [state, setState] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      const releaseDate = new Date('2024-06-10T00:00:00');
      const currentDate = new Date();
      const distance = releaseDate.getTime() - currentDate.getTime();


      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);


      setState({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            maxWidth: 450,
            position: 'relative',
            marginX: 'auto',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              borderRadius: '2.75rem',
              boxShadow: 1,
              width: '75% !important',
              marginX: 'auto',
            }}
          >
            <Box>
              <Box
                position={'relative'}
                zIndex={2}
                maxWidth={1}
                height={'auto'}
                sx={{ verticalAlign: 'middle' }}
              >
                <PhoneSkeletonIllustration />
              </Box>
              <Box
                position={'absolute'}
                top={'2.4%'}
                left={'4%'}
                width={'92.4%'}
                height={'96%'}
              >
                <Box
                  component={'img'}
                  loading="lazy"
                  src={'/images/map-mobile-2.png'}
                  alt="Image Description"
                  width={1}
                  height={1}
                  sx={{
                    objectFit: 'cover',
                    borderRadius: '2.5rem',
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <Box>
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{
                fontWeight: 700,
              }}
            >
              Coming soon to your Google Play and App Store
            </Typography>
          </Box>
          <Box my={3} mx={2}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography variant="h4" color="primary" fontWeight={'bold'}>
                  {state.days}
                </Typography>
                <Typography variant="h6">
                  Days
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h4" color="primary" fontWeight={'bold'}>
                  {state.hours}
                </Typography>
                <Typography variant="h6">
                  Hours
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h4" color="primary" fontWeight={'bold'}>
                  {state.minutes}
                </Typography>
                <Typography variant="h6">
                  Minutes
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h4" color="primary" fontWeight={'bold'}>
                  {state.seconds}
                </Typography>
                <Typography variant="h6">
                  Seconds
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <TextField
              sx={{ height: 54, marginRight: 2 }}
              label="Email"
              variant="outlined"
              color="primary"
              size="medium"
              name="firstName"
              fullWidth

            // value={formik.values.firstName}
            // onChange={formik.handleChange}
            // error={
            //   formik.touched.firstName && Boolean(formik.errors.firstName)
            // }
            // @ts-ignore
            // helperText={formik.touched.firstName && formik.errors.firstName}
            />

            <Button
              sx={{ height: 54, minWidth: 100 }}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              Submit
            </Button>
          </Box>
          <Box
            height={1}
            width={1}
            maxHeight={300}
            maxWidth={{ xs: '100%', sm: 400 }}
            marginY={3}
          >
            <Box
              component={'iframe'}
              title="video"
              width="100%"
              height="100%"
              minHeight={250}
              src="https://www.youtube.com/embed/ClIbn_IF31U"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              boxShadow={4}
              borderRadius={2}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VideoSection;
