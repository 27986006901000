import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

// import { NavItem } from './components';

// interface Props {
//   // eslint-disable-next-line @typescript-eslint/ban-types
//   onSidebarOpen: () => void;
//   pages: {
//     landings: Array<PageItem>;
//     company: Array<PageItem>;
//     account: Array<PageItem>;
//     secondary: Array<PageItem>;
//     blog: Array<PageItem>;
//     // portfolio: Array<PageItem>;
//   };
//   colorInvert?: boolean;
// }

const Topbar = (): JSX.Element => {
  // const theme = useTheme();

  // const { mode } = theme.palette;
  // const {
  //   landings: landingPages,
  //   secondary: secondaryPages,
  //   company: companyPages,
  //   account: accountPages,
  //   portfolio: portfolioPages,
  //   blog: blogPages,
  // } = pages;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        component="a"
        title="Urban Resident"
      // width={{ xs: 100, md: 130 }}
      // style={{ backgroundColor: 'blue' }}
      // textDecoration={'none'}
      >
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Typography
            component={'span'}
            variant={'h5'}
            color={'primary'}
            mr={0.3}
            fontWeight={700}
          >
            Urban
          </Typography>
          <Typography
            component={'span'}
            variant={'h5'}
            color={'text.secondary'}
            fontWeight={700}
          // mr={2}
          >
            Resident
          </Typography>
        </Link>


        {/* <Box
          component={'img'}
          src={
            mode === 'light' && !colorInvert
              ? 'https://assets.maccarianagency.com/the-front/logos/logo.svg'
              : 'https://assets.maccarianagency.com/the-front/logos/logo-negative.svg'
          }
          height={1}
          width={1}
        /> */}

        {/* <Typography
          component={'span'}
          variant={'h6'}
          color={'primary'}
        // sx={{
        //   background: `linear-gradient(180deg, transparent 82%, ${alpha(
        //     theme.palette.secondary.main,
        //     0.3,
        //   )} 0%)`,
        // }}
        >
          Urban Resident
        </Typography> */}
      </Box>

      {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box>
          <NavItem
            title={'Landings'}
            id={'landing-pages'}
            items={landingPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Company'}
            id={'company-pages'}
            items={companyPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Account'}
            id={'account-pages'}
            items={accountPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Pages'}
            id={'secondary-pages'}
            items={secondaryPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Blog'}
            id={'blog-pages'}
            items={blogPages}
            colorInvert={colorInvert}
          />
        </Box>
       <Box marginLeft={4}>
          <NavItem
            title={'Portfolio'}
            id={'portfolio-pages'}
            items={portfolioPages}
            colorInvert={colorInvert}
          />
        </Box> *
        <Box marginLeft={4}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            target="blank"
            href="https://mui.com/store/items/the-front-landing-page/"
            size="large"
          >
            Buy now
          </Button>
        </Box>
      </Box>  */}
      {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box> */}
    </Box>
  );
};

export default Topbar;
